import React,{useRef} from "react";
import img1 from '../../assets/img/Homepage/data-analysis.png'
import img2 from '../../assets/img/Homepage/benchmarking.png'
import img3 from '../../assets/img/Homepage/roadmap.png'
import Features from "./Features";

const Comprehensive = () => {
  const featureRef = useRef(null);
  return (
      <div id="featureSection" ref={featureRef} class="bg-[#0f172a] py-12 lg:py-20">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-5xl lg:text-center flex flex-col justify-center items-center">
      <h2 class="text-base font-semibold leading-7 text-blue-100 bg-blue-600 px-3  rounded-xl uppercase mb-4 lg:mb-8">
       Features and Benefits
       </h2>
      <h1 class="lg:text-5xl text-4xl md:text-5xl font-bold tracking-tight text-gray-100 text-center">Integrated Transformation 
        <span class="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-purple-800 to-gray-100"> Journey with Spectrum 360</span>
      </h1>
      {/* <p class="mt-6 text-md lg:text-lg text-gray-300 max-w-3xl text-center">
      Spectrum 360 is meticulously engineered to serve as the nucleus of your organization's transformation efforts. It provides a robust framework for assessing your current maturity levels, identifying areas of improvement, and benchmarking your progress against industry standards and peers. The core functionalities of Spectrum 360 include:
      </p> */}
    </div>
    <Features />
    {/* <div class="mx-auto py-20">
    <div className="grid gap-8 row-gap-8 lg:grid-cols-4">
        <div className="sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img src={img1} className=" w-14"/>
          </div>
          <h6 className="mb-2 font-semibold text-white text-lg lg:text-xl leading-5">Comprehensive Maturity Assessments</h6>
          <p className="max-w-md mb-3 text-sm lg:text-lg text-gray-300 sm:mx-auto">
          Utilizing advanced AI algorithms, Spectrum 360 evaluates your organization's practices, processes, and performance, offering a detailed view of your current state across various domains.
          </p>
         
        </div>
        <div className="sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img src={img2} className=" w-12"/>
          </div>
          <h6 className="mb-2 font-semibold text-white text-lg lg:text-xl leading-5">Benchmarking Insights</h6>
          <p className="max-w-md mb-3 text-sm lg:text-lg text-gray-300 sm:mx-auto">
          By comparing your organization with industry standards, Spectrum 360 offers invaluable insights into your competitive position, highlighting areas where you lead, match, or lag behind your peers.
          </p>
        
        </div>
        <div className="sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img src={img3} className=" w-12"/>
          </div>
          <h6 className="mb-2 font-semibold text-white text-lg lg:text-xl leading-5">
Customized Improvement Roadmaps</h6>
          <p className="max-w-md mb-3 text-sm lg:text-lg text-gray-300 sm:mx-auto">
          Based on the assessment and benchmarking results, Spectrum 360 generates personalized recommendations and actionable strategies tailored to your specific context and objectives.
          </p>
         
        </div>
        </div>
        
        <div className="sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img src={img1} alt="Advanced AI Assessment" className=" w-14"/>
          </div>
          <h6 className="mb-2 font-bold text-white text-lg lg:text-xl leading-5">Advanced AI Assessment</h6>
          <p className="text-pink-600 font-semibold">Utilize cutting-edge AI algorithms to evaluate your organization's current maturity level across various domains. </p>
          <p className="max-w-md mb-3 text-sm lg:text-lg text-gray-300 sm:mx-auto">
          Receive precise, data-backed insights that pinpoint critical areas for improvement.
          <br/>
           Our AI-driven recommendations guide strategic decisions, enabling targeted actions that propel your organization forward.
          </p>
         
        </div>
        <div className="sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img src={img1} alt="Advanced AI Assessment" className=" w-14"/>
          </div>
          <h6 className="mb-2 font-bold text-white text-lg lg:text-xl leading-5">Advanced AI Assessment</h6>
          <p className="text-pink-600 font-semibold">Utilize cutting-edge AI algorithms to evaluate your organization's current maturity level across various domains. </p>
          <p className="max-w-md mb-3 text-sm lg:text-lg text-gray-300 sm:mx-auto">
          Receive precise, data-backed insights that pinpoint critical areas for improvement.
          <br/>
           Our AI-driven recommendations guide strategic decisions, enabling targeted actions that propel your organization forward.
          </p>
         
        </div>
        <div className="sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img src={img1} alt="Advanced AI Assessment" className=" w-14"/>
          </div>
          <h6 className="mb-2 font-bold text-white text-lg lg:text-xl leading-5">Advanced AI Assessment</h6>
          <p className="text-pink-600 font-semibold">Utilize cutting-edge AI algorithms to evaluate your organization's current maturity level across various domains. </p>
          <p className="max-w-md mb-3 text-sm lg:text-lg text-gray-300 sm:mx-auto">
          Receive precise, data-backed insights that pinpoint critical areas for improvement.
          <br/>
           Our AI-driven recommendations guide strategic decisions, enabling targeted actions that propel your organization forward.
          </p>
         
        </div>
        <div className="sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img src={img1} alt="Advanced AI Assessment" className=" w-14"/>
          </div>
          <h6 className="mb-2 font-bold text-white text-lg lg:text-xl leading-5">Advanced AI Assessment</h6>
          <p className="text-pink-600 font-semibold">Utilize cutting-edge AI algorithms to evaluate your organization's current maturity level across various domains. </p>
          <p className="max-w-md mb-3 text-sm lg:text-lg text-gray-300 sm:mx-auto">
          Receive precise, data-backed insights that pinpoint critical areas for improvement.
          <br/>
           Our AI-driven recommendations guide strategic decisions, enabling targeted actions that propel your organization forward.
          </p>
         
        </div>
    </div> */}
    
    
    
  </div>
</div>
 
  );
};

export default Comprehensive;
