import React from "react";
import img1 from "../../assets/img/Homepage/woman-scrolling-laptop.jpg";
const Benchmark = () => {
  return (
    <section class="bg-[#0f172a] ">
    <div class="container px-6 lg:px-20 py-10 mx-auto">
        <div class="lg:flex lg:items-center place-items-center">

        <div class="hidden lg:flex lg:items-center lg:w-1/2 lg:justify-center">
                <img class="w-[28rem] h-[28rem] object-cover xl:w-[34rem] xl:h-[34rem] rounded-full" src={img1} alt=""/>
            </div>
            <div class="w-full space-y-12 lg:w-1/2 ">
                <div>
                    <h1 class="text-2xl font-semibold  capitalize lg:text-4xl text-white"><span className=" text-purple-400">Spectra Store</span> <br/> A Repository of Maturity Frameworks</h1>

                    <div class="mt-2">
                        <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
                        <span class="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"></span>
                        <span class="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"></span>
                    </div>
                   
<p className=" text-white pt-2 lg:text-lg">Integration with Spectra Store further amplifies the value delivered by Spectrum 360. Recognizing the diverse needs and strategic priorities of organizations, Spectra Store offers:</p>
                </div>
                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2 bg-blue-500  rounded-xl md:mx-4 text-white ">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-8 lg:w-6 lg:h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                        </svg>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-xl font-semibold  capitalize text-white">Access to Maturity Frameworks</h1>

                        <p class="mt-3 lg:text-lg text-gray-300 dark:text-gray-300">
                        A rich collection of maturity frameworks across various practice areas, including DevOps, Cloud, Agile, Business, and more. These frameworks are contributed by leading organizations and are available for use within the Spectrum 360 assessments.
                        </p>
                    </div>
                </div>

                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2 bg-blue-500  rounded-xl md:mx-4 text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-8 lg:w-6 lg:h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                        </svg>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-xl font-semibold  capitalize text-white">Tailored to Organizational Needs</h1>

                        <p class="mt-3 lg:text-lg text-gray-300 dark:text-gray-300">
                        Whether your organization seeks to pioneer new practices or strengthen existing ones, Spectra Store provides the necessary frameworks to guide your maturity assessment and improvement efforts.
                        </p>
                    </div>
                </div>

                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2 bg-blue-500  rounded-xl md:mx-4 text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-8 lg:w-6 lg:h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z" />
                        </svg>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-xl font-semibold  capitalize text-white">Ease of Use</h1>

                        <p class="mt-3 lg:text-lg text-gray-300 dark:text-gray-300">
                        Frameworks from Spectra Store are seamlessly integrated into the Spectrum 360 platform, ensuring that organizations can easily select and apply them without the need for separate sign-ins or complex integrations.
                        </p>
                    </div>
                </div>
            </div>

            
        </div>

        <hr class="my-12 border-gray-700"/>

     
    </div>
</section>
  );
};

export default Benchmark;
