import React from 'react'
import img2 from '../../assets/img/Homepage/person-office-analyzing-checking-finance-graphs.jpg'
import img1 from '../../assets/img/Homepage/workplace-analyst.jpg'
export default function Section3() {
  return (
    <div>
        <section className="bg-[#0f172a] ">
	<div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		
		<div className="grid lg:gap-8 lg:grid-cols-2 place-items-center lg:items-center">
			<div>
				
				
				<div className="mt-12 space-y-12">
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md bg-[#ff5cf4] dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-normal leadi text-gray-300 dark:text-gray-50">By using the Spectrum 360 dashboard, organizations can gain a holistic view of their performance and make informed decisions that drive their success.</h4>
							
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md bg-[#ff5cf4] dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-normal leadi text-gray-300 dark:text-gray-50">It serves as a powerful tool for organizations to identify areas for improvement, implement effective solutions, and track their progress towards their goals.</h4>
						
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-12 h-12 rounded-md bg-[#ff5cf4] dark:text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="w-7 h-7">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-normal leadi text-gray-300 dark:text-gray-50">With its user-friendly interface and customizable features, the Spectrum 360 dashboard is a valuable asset for any organization looking to optimize their performance and achieve sustainable growth.</h4>
						
						</div>
					</div>
				</div>
			</div>
			<div aria-hidden="true" className="mt-10 lg:mt-0">
				<img src={img1} alt="" className="mx-auto w-96 rounded-lg shadow-lg dark:bg-gray-500" />
			</div>
		</div>
		<div>
			<div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div className="lg:col-start-2">
					
					<div className="mt-12 space-y-12">
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-12 h-12 rounded-md bg-[#ff5cf4] dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="w-7 h-7">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-normal leadi text-gray-300 dark:text-gray-50">Spectrum 360 dashboard offers real-time data and analytics, allowing organizations to stay on top of their performance at all times.</h4>
								
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-12 h-12 rounded-md bg-[#ff5cf4] dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="w-7 h-7">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-normal leadi text-gray-300 dark:text-gray-50">This feature is particularly useful for organizations that operate in fast-paced and dynamic environments, as it enables them to quickly adapt to changing circumstances and make necessary adjustments to their plans and strategies.</h4>
							
							</div>
						</div>
						
					</div>
				</div>
				<div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
					<img src={img2} alt="" className="mx-auto w-96 rounded-lg shadow-lg dark:bg-gray-500" />
				</div>
			</div>
		</div>
	</div>
</section>
    </div>
  )
}
