import React from "react";
import HeroSection3 from './HeroSection3'
import HeroSection2 from "./HeroSection2";
import LastSection from "./LastSection";

import { Fade, Slide } from "react-awesome-reveal";
import Comprehensive from "./Comprehensive";
import Personalized from "./Personalized";
import Benchmark from "./Benchmark";

import Pricing from '../Pricing/index'
import Transforming from "./Transforming";
import Section4 from "./Section4";
import Paralaxx from "./ParalaxxSection1";
import Section3 from "./Section3";
import StepsSection from "./StepsSection";
import WhyMS360 from "./WhyMS360";

import TargetAudience from "./TargetAudience";
export default function index() {
  return (
    <div>
      <Fade duration={500} triggerOnce>
{/*      
        <HeroSection2/> */}
        <HeroSection3 />
      </Fade>
      <Paralaxx/>
      <Comprehensive />
      <WhyMS360 />
      <TargetAudience />
      
    <Section3/>
      <Personalized/>

      <Benchmark/>
      <Section4/>
      <Transforming/>
      <StepsSection/>
     <Pricing/>
      <LastSection/>
    </div>
  );
}
