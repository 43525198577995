
// import Target from '../../pages/Homepage/targetaudience/ms360.jpeg'
import Logo1 from '../Homepage/targetaudience/leader (1).png'
import Logo2 from '../Homepage/targetaudience/management.png'
import Logo3 from '../Homepage/targetaudience/strategies.png'
const targerImage = "https://images.unsplash.com/photo-1553877522-43269d4ea984?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDF8fG9mZmljZXxlbnwwfHwwfHx8Mg%3D%3D";
const TargetAudience = () => {
    const features = [
        {
          icon: 
            <img src={Logo1} alt="" />
          ,
          title: "CEOs and CTOs",
          desc: "Who are steering their companies through digital transformation and need a reliable tool to measure progress and guide strategic decisions."
        },
        {
          icon: (
            <img src={Logo2} alt="" />
          ),
          title: "Transformation Managers",
          desc: "Who manage day-to-day operations and require a comprehensive toolset to track and report on transformation initiatives."
        },
        {
          icon: (
            <img src={Logo3} alt="" />
          ),
          title: "IT and Business Leaders",
          desc: "Looking for robust benchmarking tools and tailored recommendations to push their departments towards industry-leading practices."
        }
      ];
      

return (
    <>
    
    <section className=" relative py-28 bg-[#0f172a]">
    <div className='mb-28'>
    <h1 class="lg:text-5xl text-4xl md:text-5xl font-bold tracking-tight text-gray-100 text-center">Target 
        <span class="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-purple-800 to-gray-100">  Audience</span>
        
      </h1>

    </div>
        <div className="relative z-10 max-w-screen-xl mx-auto px-4 text-gray-300 justify-between content-center  gap-14 lg:flex md:px-8">
            <div className=" max-w-4xl  md:mx-auto">
            <img
                            src={targerImage}
                            className=" lg:rounded-tr-[108px] md:mx-auto  "
                            alt="target audience"
                        />
            </div>
            <div className="mt-12 lg:mt-0">
              {/* <h1 className='mb-7 text-2xl font-bold '>Spectrum 360 is ideal for:</h1> */}
                <ul className="gap-8 flex flex-wrap justify-center">
                    {
                        features.map((item, idx) => (
                            <li key={idx} className="w-72">
                                <div className="flex-none w-12 h-12 rounded-lg flex items-center justify-center">
                                    {item.icon}
                                </div>
                                <div>
                                    <h4 className="text-xl  text-white font-bold mt-1">
                                        {item.title}
                                    </h4>
                                    <p className="mt-3">
                                        {item.desc}
                                    </p>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
        <div className="absolute inset-0 max-w-md mx-auto h-72 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)" }}></div>
    </section>
    </>
)
}

export default TargetAudience