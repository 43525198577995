import React, { useEffect } from 'react';

function Features() {
  useEffect(() => {
    const loadKeenSlider = async () => {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/keen-slider@6.8.6/+esm';
      script.type = 'module';
      document.body.appendChild(script);
      
      script.onload = () => {
        const keenSlider = new window.KeenSlider('#keen-slider', {
          loop: true,
          slides: {
            origin: 'center',
            perView: 1.25,
            spacing: 16,
          },
          breakpoints: {
            '(min-width: 1024px)': {
              slides: {
                origin: 'auto',
                perView: 1.5,
                spacing: 32,
              },
            },
          },
        });
        
        const keenSliderPrevious = document.getElementById('keen-slider-previous')
  const keenSliderNext = document.getElementById('keen-slider-next')

  const keenSliderPreviousDesktop = document.getElementById('keen-slider-previous-desktop')
  const keenSliderNextDesktop = document.getElementById('keen-slider-next-desktop')

  keenSliderPrevious.addEventListener('click', () => keenSlider.prev())
  keenSliderNext.addEventListener('click', () => keenSlider.next())

  keenSliderPreviousDesktop.addEventListener('click', () => keenSlider.prev())
  keenSliderNextDesktop.addEventListener('click', () => keenSlider.next())
      };
    };

    loadKeenSlider();

    return () => {
      // Cleanup if needed
    };
  }, []);

  return (
    <section className="bg-[#0f172a]">
  <div className="mx-auto max-w-[1340px] px-4 py-12 sm:px-6 lg:me-0 lg:py-16 lg:pe-0 lg:ps-8 xl:py-24">
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-3 lg:items-center lg:gap-16">
      <div className="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
        

        <p className="mt-4 text-md  font-semibold lg:text-lg text-gray-300 max-w-3xl text-center">
        Spectrum 360 is meticulously engineered to serve as the nucleus of your organization's transformation efforts. It provides a robust framework for assessing your current maturity levels, identifying areas of improvement, and benchmarking your progress against industry standards and peers. The core functionalities of Spectrum 360 include:
        </p>

        <div className="hidden lg:mt-8 lg:flex lg:gap-4">
          <button
            aria-label="Previous slide"
            id="keen-slider-previous-desktop"
            className="rounded-full border border-blue-600 p-3 text-blue-600 transition hover:bg-blue-600 hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5 rtl:rotate-180"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </button>

          <button
            aria-label="Next slide"
            id="keen-slider-next-desktop"
            className="rounded-full border border-blue-600 p-3 text-blue-600 transition hover:bg-blue-600 hover:text-white"
          >
            <svg
              className="size-5 rtl:rotate-180"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 5l7 7-7 7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="-mx-6 lg:col-span-2 lg:mx-0">
        <div id="keen-slider" className="keen-slider">
          <div className="keen-slider__slide rounded-xl">
            <blockquote
              className="flex h-full flex-col justify-between bg-slate-800 p-6 shadow-sm sm:p-8 lg:p-12"
            >
              <div>

                <div className="mt-1">
                  <p className="text-2xl font-bold text-pink222 sm:text-3xl">Advanced AI Assessment</p>
                  <p className='text-md text-blue-500 font-semibold mt-2'>Utilize cutting-edge AI algorithms to evaluate your organization's current maturity level across various domains. </p>

                  <p className=" leading-relaxed text-gray-100 mt-5">
                  Receive precise, data-backed insights that pinpoint critical areas for improvement.<br/> Our AI-driven recommendations guide strategic decisions, enabling targeted actions that propel your organization forward.
                  </p>
                </div>
              </div>

              
            </blockquote>
          </div>
{/* feature 2 */}
          <div className="keen-slider__slide rounded-xl">
            <blockquote
              className="flex h-full flex-col justify-between bg-slate-800 p-6 shadow-sm sm:p-8 lg:p-12"
            >
              <div>

                <div className="mt-1">
                  <p className="text-2xl font-bold text-pink222 sm:text-3xl">In-depth Reporting & Analytics</p>
                  <p className='text-md font-semibold mt-2 text-blue-500'>Gain access to detailed reports and analytics that provide a clear view of your organization’s strengths and weaknesses, alongside benchmark comparisons with industry standards.  </p>

                  <p className="mt-4 leading-relaxed text-gray-100">
                  These insights form the foundation for informed strategic planning, helping you understand where you stand against competitors and where you can excel.
                  <br /> Use this intelligence to prioritize initiatives and allocate resources effectively.
                  </p>
                </div>
              </div>

              
            </blockquote>
          </div>
          {/* feature-3 */}
          <div className="keen-slider__slide rounded-xl">
            <blockquote
              className="flex h-full flex-col justify-between bg-slate-800 p-6 shadow-sm sm:p-8 lg:p-12"
            >
              <div>

                <div className="mt-1">
                  <p className="text-2xl font-bold text-pink222 sm:text-3xl">Gamified User Experience</p>
                  <p className='text-md font-semibold text-blue-500 mt-2'>Transform the maturity assessment process into a dynamic and engaging journey with our gamification features. Earn badges and see how your organization ranks in various practice areas such as Business Transformation, DevOps, and Cloud Technologies.  </p>

                  <p className="mt-4 leading-relaxed text-gray-100">
                  This engaging approach not only motivates teams but also clarifies progression paths in each practice area, making the pursuit of excellence both fun and rewarding.
                  </p>
                </div>
              </div>

              
            </blockquote>
          </div>
          <div className="keen-slider__slide rounded-xl">
            <blockquote
              className="flex h-full flex-col justify-between bg-slate-800 p-6 shadow-sm sm:p-8 lg:p-12"
            >
              <div>

                <div className="mt-1">
                  <p className="text-2xl font-bold text-pink222 sm:text-3xl">Maturity Score and Badges</p>
                  <p className='text-md font-semibold mt-2 text-blue-500'>Each practice area within your organization is evaluated and scored, allowing you to earn badges that represent your proficiency and maturity.  </p>

                  <p className="mt-4 leading-relaxed text-gray-100">
                  Badges serve as a visual benchmark for comparison with peer organizations, providing recognition for your team’s efforts and achievements.<br /> This feature fosters a healthy competitive spirit and a clear framework for continuous improvement.
                  </p>
                </div>
              </div>

              
            </blockquote>
          </div>
          <div className="keen-slider__slide rounded-xl">
            <blockquote
              className="flex h-full flex-col justify-between bg-slate-800 p-6 shadow-sm sm:p-8 lg:p-12"
            >
              <div>

                <div className="mt-1">
                  <p className="text-2xl font-bold text-pink222 sm:text-3xl">Timeline View & Notifications</p>
                  <p className='text-md font-semibold mt-2 text-blue-500'>Our platform offers a timeline view of your maturity journey, integrated with smart notifications to keep your progress on track.  </p>

                  <p className="mt-4 leading-relaxed text-gray-100">
                  This feature enables organizations to visualize their growth over time, track milestones, and receive reminders for assessments and reviews.<br /> Adjust strategies and focus efforts where they are most needed to ensure ongoing advancement and adaptability.
                  </p>
                </div>
              </div>

              
            </blockquote>
          </div>
          <div className="keen-slider__slide rounded-xl">
            <blockquote
              className="flex h-full flex-col justify-between bg-slate-800 p-6 shadow-sm sm:p-8 lg:p-12"
            >
              <div>

                <div className="mt-1">
                  <p className="text-2xl font-bold text-pink222 sm:text-3xl">Custom Framework Integration</p>
                  <p className='text-blue-500 text-md font-semibold mt-2'>Spectrum 360 allows for the integration of both industry-standard and custom-developed maturity frameworks specific to your organization's needs.  </p>

                  <p className="mt-4 leading-relaxed text-gray-100">
                  Tailor your assessment to reflect unique organizational structures, goals, and industry requirements, enabling a more relevant and precise maturity evaluation.
                  </p>
                </div>
              </div>

              
            </blockquote>
          </div>
          <div className="keen-slider__slide rounded-xl">
            <blockquote
              className="flex h-full flex-col justify-between bg-slate-800 p-6 shadow-sm sm:p-8 lg:p-12"
            >
              <div>

                <div className="mt-1">
                  <p className="text-2xl font-bold text-pink222 sm:text-3xl">Predictive Analytics and Forecasting</p>
                  <p className='text-md font-semibold mt-2 text-blue-500'>Utilize advanced predictive models to forecast future maturity levels and potential industry shifts.  </p>

                  <p className="mt-4 leading-relaxed text-gray-100">
                  Prepare for future challenges and opportunities with predictive insights that help you plan strategic initiatives proactively, reducing risks and enhancing decision-making processes.
                  </p>
                </div>
              </div>

              
            </blockquote>
          </div>
          <div className="keen-slider__slide rounded-xl">
            <blockquote
              className="flex h-full flex-col justify-between bg-slate-800 p-6 shadow-sm sm:p-8 lg:p-12"
            >
              <div>

                <div className="mt-1">
                  <p className="text-2xl font-bold text-pink222 sm:text-3xl">Real-time Dashboard Updates</p>
                  <p className='text-blue-500 text-md font-semibold mt-2'>The Spectrum 360 dashboard updates in real-time, providing immediate feedback on your organization’s actions and their impacts on your maturity scores.  </p>

                  <p className="mt-4 leading-relaxed text-gray-100">
                  Make agile decisions with up-to-the-minute data, ensuring your organization can quickly adapt to changes in strategy or performance.
                  </p>
                </div>
              </div>

              
            </blockquote>
          </div>
          <div className="keen-slider__slide rounded-xl">
            <blockquote
              className="flex h-full flex-col justify-between bg-slate-800 p-6 shadow-sm sm:p-8 lg:p-12"
            >
              <div>

                <div className="mt-1">
                  <p className="text-2xl font-bold text-pink222 sm:text-3xl">Deep Dive Analytical Tools</p>
                  <p className='text-md font-semibold mt-2 text-blue-500'>Access advanced analytical tools that allow for deep dives into specific data points. Perform complex analyses such as cohort analysis, trend analysis, and what-if scenarios. </p>

                  <p className="mt-4 leading-relaxed text-gray-100">
                  Gain a deeper understanding of the drivers behind your maturity metrics, enabling targeted improvements and more sophisticated strategic planning.
                  </p>
                </div>
              </div>

              
            </blockquote>
          </div>
          <div className="keen-slider__slide rounded-xl">
            <blockquote
              className="flex h-full flex-col justify-between bg-slate-800 p-6 shadow-sm sm:p-8 lg:p-12"
            >
              <div>

                <div className="mt-1">
                  <p className="text-2xl font-bold text-pink222 sm:text-3xl">Role-Based Access and Customization</p>
                  <p className='text-md font-semibold text-blue-500 mt-2'>Customize information and dashboard views based on user roles within your organization, ensuring that each team member receives relevant data and insights.  </p>

                  <p className="mt-4 leading-relaxed text-gray-100">
                  Enhance operational efficiency and personalization, empowering employees by providing them with the tools and information they need to contribute effectively to transformation goals.
                  </p>
                </div>
              </div>

              
            </blockquote>
          </div>
          {/* WE REMOVE IT LATER--------- */}
          <div className="keen-slider__slide rounded-xl">
            <blockquote
              className="flex h-full flex-col justify-between bg-slate-800 p-6 shadow-sm sm:p-8 lg:p-12"
            >
              <div>

                <div className="mt-1">
                  <p className="text-2xl font-bold text-pink222 sm:text-3xl">Comprehensive Maturity Assessments</p>
                  {/* <p className='text-md font-semibold text-blue-500 mt-2'>Customize information and dashboard views based on user roles within your organization, ensuring that each team member receives relevant data and insights.  </p> */}

                  <p className="mt-4 leading-relaxed text-gray-100">
                  Utilizing advanced AI algorithms, Spectrum 360 evaluates your organization's practices, processes, and performance, offering a detailed view of your current state across various domains.
                  </p>
                </div>
              </div>

              
            </blockquote>
          </div>
          <div className="keen-slider__slide rounded-xl">
            <blockquote
              className="flex h-full flex-col justify-between bg-slate-800 p-6 shadow-sm sm:p-8 lg:p-12"
            >
              <div>

                <div className="mt-1">
                  <p className="text-2xl font-bold text-pink222 sm:text-3xl">Benchmarking Insights</p>
                  {/* <p className='text-md font-semibold text-blue-500 mt-2'>Customize information and dashboard views based on user roles within your organization, ensuring that each team member receives relevant data and insights.  </p> */}

                  <p className="mt-4 leading-relaxed text-gray-100">
                  By comparing your organization with industry standards, Spectrum 360 offers invaluable insights into your competitive position, highlighting areas where you lead, match, or lag behind your peers.
                  </p>
                </div>
              </div>

              
            </blockquote>
          </div>
          <div className="keen-slider__slide rounded-xl">
            <blockquote
              className="flex h-full flex-col justify-between bg-slate-800 p-6 shadow-sm sm:p-8 lg:p-12"
            >
              <div>

                <div className="mt-1">
                  <p className="text-2xl font-bold text-pink222 sm:text-3xl">Customized Improvement Roadmaps</p>
                  {/* <p className='text-md font-semibold text-blue-500 mt-2'>Customize information and dashboard views based on user roles within your organization, ensuring that each team member receives relevant data and insights.  </p> */}

                  <p className="mt-4 leading-relaxed text-gray-100">
                  Based on the assessment and benchmarking results, Spectrum 360 generates personalized recommendations and actionable strategies tailored to your specific context and objectives.
                  </p>
                </div>
              </div>

              
            </blockquote>
          </div>

          
          
        </div>
      </div>
    </div>

    <div className="mt-8 flex justify-center gap-4 lg:hidden">
      <button
        aria-label="Previous slide"
        id="keen-slider-previous"
        className="rounded-full border border-blue-600 p-4 text-blue-600 transition hover:bg-blue-600 hover:text-white"
      >
        <svg
          className="size-5 -rotate-180 transform"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9 5l7 7-7 7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </svg>
      </button>

      <button
        aria-label="Next slide"
        id="keen-slider-next"
        className="rounded-full border border-blue-600 p-4 text-blue-600 transition hover:bg-blue-600 hover:text-white"
      >
        <svg
          className="h-5 w-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9 5l7 7-7 7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </svg>
      </button>
    </div>
  </div>
</section>
  );
}

export default Features;
