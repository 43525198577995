import React from "react";
import img1 from "../../assets/img/Homepage/modern-equipped-computer-lab.jpg";
import img_icon1 from "../../assets/img/Homepage/connect-svgrepo-com.svg";
import img_icon2 from "../../assets/img/Homepage/exchange-horizontal-svgrepo-com.svg";

const Personalized = () => {
  return (
    <section class="bg-[#0f172a] pt-10  ">
    <div class="container px-6 lg:px-20 py-10 mx-auto">
        <div class="lg:flex lg:items-center place-items-center">
            <div class="w-full space-y-12 lg:w-1/2 ">
                <div>
                    <h1 class="text-2xl font-semibold  capitalize lg:text-4xl text-white"><span className=" text-purple-400">Spectra Hub</span> <br/> Enriching the Ecosystem with Expertise</h1>

                    <div class="mt-2">
                        <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
                        <span class="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"></span>
                        <span class="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"></span>
                    </div>
                   
<p className=" text-white lg:text-lg pt-2">For organizations that lack specialized practice teams or Centers of Excellence (CoE), Spectra Hub steps in to fill this crucial gap. As an integrated assessor and social connector portal, Spectra Hub enhances the Spectrum 360 ecosystem by:</p>
                </div>
                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2  text-blue-500  rounded-xl md:mx-4  bg-blue-500">
                       <img src={img_icon1} className="w-8 lg:w-16"></img>
                      
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-xl font-semibold  capitalize text-white">Connecting with Assessors</h1>

                        <p class="mt-3 lg:text-lg text-gray-300 dark:text-gray-300">
                        Providing access to a broad network of service organizations and consultants registered to conduct maturity assessments. This connection ensures that organizations can leverage external expertise to accurately evaluate their maturity levels and identify improvement areas.
                        </p>
                    </div>
                </div>

                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2 text-blue-500  rounded-xl md:mx-4  bg-blue-500">
                    <img src={img_icon2} className="w-8 lg:w-16"></img>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-xl font-semibold  capitalize text-white">Facilitating Knowledge Exchange</h1>

                        <p class="mt-3 text-gray-300 lg:text-lg dark:text-gray-300">
                        Spectra Hub fosters a vibrant community of experts, practitioners, and leaders who share insights, best practices, and innovative solutions. This collaborative environment encourages learning and continuous improvement among organizations.
                        </p>
                    </div>
                </div>

              
            </div>

            <div class="hidden lg:flex lg:items-center lg:w-1/2 lg:justify-center">
                <img class="w-[28rem] h-[28rem] object-cover xl:w-[34rem] xl:h-[34rem] rounded-full" src={img1} alt=""/>
            </div>
        </div>

        <hr class="my-12 border-gray-700"/>

     
    </div>
</section>
  );
};

export default Personalized;
