import Logo1 from '../../pages/Homepage/whyms360/assessment.png'
import Logo2 from '../Homepage/whyms360/data-analysis.png'
import Logo3 from '../Homepage/whyms360/recommend.png'
import Logo4 from '../Homepage/whyms360/integration.png'
import Logo5 from '../Homepage/whyms360/roadmap.png'
import Logo6 from '../Homepage/whyms360/analytics (1).png'
import Logo7 from '../Homepage/whyms360/community.png'
import Logo8 from '../Homepage/whyms360/size.png'




const WhyMS360 =()=>{
  return (
    <section className="bg-[#0f172a] text-white">
  <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
    <div className="mx-auto max-w-lg text-center">
    <h2 className="max-w-xl mb-6  text-2xl font-bold tracking-tight text-white lg:text-4xl sm:leading-none">
    Why Choose 
                <span className="inline-block text-purple-400 ml-2">
                Spectrum 360?
                </span>
              </h2>

      {/* <p className="mt-4 text-gray-300">
      Whether you're just starting your maturity assessment or looking to refine existing strategies, Spectrum 360 offers the tools, insights, and community support needed to succeed. Join leading organizations around the globe who trust Spectrum 360 to guide their transformation journey.
      </p> */}
    </div>

    <div className="mt-16  gap-8 flex flex-wrap justify-center  ">
      <div
        className="block rounded-xl w-96  border border-gray-800 p-8 shadow-xl transition hover:border-pink-500/10 hover:shadow-pink222/50">
        <img src={Logo1} alt="" className='w-12' />
        

        <h2 className="mt-4 text-xl font-bold text-white">Holistic Maturity Assessments</h2>

        <p className="mt-1 text-sm text-gray-300">
        Utilize our AI-driven tools to gain a deep understanding of your organization's current maturity across multiple dimensions, including technology adoption, operational efficiency, and innovation capabilities.
        </p>
      </div>

      <div
        className="w-96  block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-pink-500/10 hover:shadow-pink222/50"
        
      >
        <img src={Logo2} alt="" className='w-12' />

        <h2 className="mt-4 text-xl font-bold text-white">Dynamic Benchmarking</h2>

        <p className="mt-1 text-sm text-gray-300">
        Compare your organization’s performance against industry peers and leaders to identify your strengths and areas for improvement. Our real-time data ensures you have the latest benchmarks at your fingertips.
        </p>
      </div>

      <div
        className="w-96  block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-pink-500/10 hover:shadow-pink222/50"
        href="#"
      >
        <img src={Logo3} alt="" className='w-12' />

        <h2 className="mt-4 text-xl font-bold text-white">Customized Recommendations</h2>

        <p className="mt-1 text-sm text-gray-300">
        Receive actionable insights and personalized strategies based on your unique assessment results. Our AI algorithms provide you with the best practices and innovative solutions tailored to your specific needs.
        </p>
      </div>

      <div
        className="w-96  block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-pink-500/10 hover:shadow-pink222/50"
        href="#"
      >
       <img src={Logo4} alt="" className='w-12' />

        <h2 className="mt-4 text-xl font-bold text-white">Integration with Management Tools</h2>

        <p className="mt-1 text-sm text-gray-300">
        Seamlessly integrate with popular project management tools like Jira and Azure Boards, allowing you to effectively implement and track the progress of recommended strategies directly from your dashboard.
        </p>
      </div>

      <div
        className="w-96  block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-pink-500/10 hover:shadow-pink222/50"
        href="#"
      >
        <img src={Logo5} alt="" className='w-12' />

        <h2 className="mt-4 text-xl font-bold text-white">Roadmap and Goal Setting</h2>

        <p className="mt-1 text-sm text-gray-300">
        Define clear, strategic objectives with our roadmap planning tools. Set actionable goals and monitor your progress in real-time to stay on track towards achieving organizational excellence.
        </p>
      </div>

      <div
        className="w-96  block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-pink-500/10 hover:shadow-pink222/50"
        
      >
        <img src={Logo6} alt="" className='w-12' />

        <h2 className="mt-4 text-xl font-bold text-white">Advanced Analytics and Reporting</h2>

        <p className="mt-1 text-sm text-gray-300">
        Access detailed analytics and generate custom reports to share insights with stakeholders and inform decision-making processes. Our intuitive dashboard visualizes data in an easily digestible format, making complex information accessible to all.
        </p>
      </div>
      <div
        className="w-96  block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-pink-500/10 hover:shadow-pink222/50"
        
      >
        <img src={Logo7} alt="" className='w-12' />

        <h2 className="mt-4 text-xl font-bold text-white">Community and Collaboration</h2>

        <p className="mt-1 text-sm text-gray-300">
        Join a vibrant community of industry leaders, experts, and peers within the Spectra Hub. Engage in discussions, share best practices, and gain insights from those who are also on the path to transformation.
        </p>
      </div>
      <div
        className="w-96  block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-pink-500/10 hover:shadow-pink222/50"
        
      >
        <img src={Logo8} alt="" className='w-12' />

        <h2 className="mt-4 text-xl font-bold text-white">Marketplace Access</h2>

        <p className="mt-1 text-sm text-gray-300">
        Enhance your capabilities by accessing a variety of maturity frameworks through the Spectra Store. Choose from an extensive library of proven frameworks developed by leading organizations to accelerate your growth.
        </p>
      </div>
    </div>

    
  </div>
</section>
  )
}

export default WhyMS360;