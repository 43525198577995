import React from 'react'
import img1 from '../../assets/img/Homepage/document.png'
import img2 from '../../assets/img/Homepage/onboarding.png'
import img3 from '../../assets/img/Homepage/choice.png'
import img4 from '../../assets/img/Homepage/recommendation.png'
import img5 from '../../assets/img/Homepage/assessment2.png'
import img6 from '../../assets/img/Homepage/customization.png'
export default function StepsSection() {
  return (
    <div className='bg-[#0f172a]'>
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
 <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
   <div>
     <p className="inline-block px-3 py-1 mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-400">
      
     </p>
   </div>
   <h2 className="max-w-2xl mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
   Getting Started<span className='text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-purple-300 to-gray-100'> with Spectrum 360 </span>
   </h2>
   <p className="text-base text-gray-100 md:text-lg">
   Embrace the future of your organization with Spectrum 360. Whether you're just starting your maturity assessment or looking to refine existing strategies, Spectrum 360 offers the tools, insights, and community support needed to succeed. Join leading organizations around the globe who trust Spectrum 360 to guide their transformation journey.
        </p>
 </div>
 <div className="grid gap-8 row-gap-0 lg:grid-cols-3">
   <div className="relative text-center">
     <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20">
      <img src={img1} className='w-12'/>
     </div>
     <h6 className="mb-2 text-2xl text-white font-bold">Register</h6>
     <p className="max-w-md mb-3 text-sm md:text-lg text-gray-300 sm:mx-auto">
    
Sign up using your corporate details to create an account on the platform.
     </p>
    
     <div className="top-0 right-0 flex items-center justify-center h-24 lg:-mr-8 lg:absolute">
       <svg
         className="w-8 text-gray-700 transform rotate-90 lg:rotate-0"
         stroke="currentColor"
         strokeWidth="2"
         strokeLinecap="round"
         strokeLinejoin="round"
         viewBox="0 0 24 24"
       >
         <line
           fill="none"
           strokeMiterlimit="10"
           x1="2"
           y1="12"
           x2="22"
           y2="12"
         />
         <polyline
           fill="none"
           strokeMiterlimit="10"
           points="15,5 22,12 15,19 "
         />
       </svg>
     </div>
   </div>
   <div className="relative text-center">
     <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20">
     <img src={img2} className='w-12'/>
     </div>
     <h6 className="mb-2 text-2xl text-white font-bold">Onboard</h6>
     <p className="max-w-md mb-3 text-sm md:text-lg text-gray-300 sm:mx-auto">
     Complete the onboarding process by providing essential information about your organization and its practices.


     </p>
    
     <div className="top-0 right-0 flex items-center justify-center h-24 lg:-mr-8 lg:absolute">
       <svg
         className="w-8 text-gray-700 transform rotate-90 lg:rotate-0"
         stroke="currentColor"
         strokeWidth="2"
         strokeLinecap="round"
         strokeLinejoin="round"
         viewBox="0 0 24 24"
       >
         <line
           fill="none"
           strokeMiterlimit="10"
           x1="2"
           y1="12"
           x2="22"
           y2="12"
         />
         <polyline
           fill="none"
           strokeMiterlimit="10"
           points="15,5 22,12 15,19 "
         />
       </svg>
     </div>
   </div>
   <div className="relative text-center">
     <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20">
     <img src={img3} className='w-12'/>
     </div>
     <h6 className="mb-2 text-2xl text-white font-bold">Choose your Maturity Models</h6>
     <p className="max-w-md mb-3 text-sm md:text-lg text-gray-300 sm:mx-auto">
     Select the specific maturity models or domains you want to assess within your organization.
     </p>
    
     <div className="top-0 right-0 flex items-center justify-center h-24 lg:-mr-8 lg:absolute">
       <svg
         className="w-8 text-gray-700 transform rotate-90  lg:hidden"
         stroke="currentColor"
         strokeWidth="2"
         strokeLinecap="round"
         strokeLinejoin="round"
         viewBox="0 0 24 24"
       >
         <line
           fill="none"
           strokeMiterlimit="10"
           x1="2"
           y1="12"
           x2="22"
           y2="12"
         />
         <polyline
           fill="none"
           strokeMiterlimit="10"
           points="15,5 22,12 15,19 "
         />
       </svg>
     </div>
   </div>
   <div className="relative text-center">
     <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20">
     <img src={img6} className='w-12'/>
     </div>
     <h6 className="mb-2 text-2xl text-white font-bold">Customize Maturity Framework</h6>
     <p className="max-w-md mb-3 text-sm md:text-lg text-gray-300 sm:mx-auto">
     Tailor your maturity assessment framework to align with your unique business needs and practices.
     </p>
    
     <div className="top-0 right-0 flex items-center justify-center h-24 lg:-mr-8 lg:absolute">
       <svg
         className="w-8 text-gray-700 transform rotate-90 lg:rotate-0"
         stroke="currentColor"
         strokeWidth="2"
         strokeLinecap="round"
         strokeLinejoin="round"
         viewBox="0 0 24 24"
       >
         <line
           fill="none"
           strokeMiterlimit="10"
           x1="2"
           y1="12"
           x2="22"
           y2="12"
         />
         <polyline
           fill="none"
           strokeMiterlimit="10"
           points="15,5 22,12 15,19 "
         />
       </svg>
     </div>
   </div>
  
   
  
   <div className="relative text-center">
     <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20">
     <img src={img5} className='w-12'/>
     </div>
     <h6 className="mb-2 text-2xl text-white font-bold">Assessment</h6>
     <p className="max-w-md mb-3 text-sm md:text-lg text-gray-300 sm:mx-auto">
     Conduct assessments using various methods, such as self-assessment, third-party assessment, or AI-based assessment.
     </p>
    
     <div className="top-0 right-0 flex items-center justify-center h-24 lg:-mr-8 lg:absolute">
       <svg
         className="w-8 text-gray-700 transform rotate-90 lg:rotate-0"
         stroke="currentColor"
         strokeWidth="2"
         strokeLinecap="round"
         strokeLinejoin="round"
         viewBox="0 0 24 24"
       >
         <line
           fill="none"
           strokeMiterlimit="10"
           x1="2"
           y1="12"
           x2="22"
           y2="12"
         />
         <polyline
           fill="none"
           strokeMiterlimit="10"
           points="15,5 22,12 15,19 "
         />
       </svg>
     </div>
   </div>
   
   <div className="relative text-center">
     <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20">
     <img src={img4} className='w-12'/>
     </div>
     <h6 className="mb-2 text-2xl text-white font-bold">Receive Recommendations</h6>
     <p className="max-w-md mb-3 text-sm md:text-lg text-gray-300 sm:mx-auto">
     Receive Al-powered recommendations, actionable insights,
and benchmarking data to improve your organization's
maturity levels.
     </p>
    
     <div className="top-0 right-0 flex items-center justify-center h-24 lg:-mr-8 lg:absolute">
      
     </div>
   </div>
 </div>
</div>
</div>
  )
}
