import { React, useState,  useRef } from "react";


import Modal from "../../pages/Modal/Modal";
import checkgrey_img from "../../assets/img/check-grey.svg";
// import checkwhite_img from "../../assets/img/check-white.svg";
import arrow_img from "../../assets/img/arrow-right.svg";
import img1 from "../../assets/img/abstract-1.jpg";
import img2 from "../../assets/img/abstract-2.jpg";
import img3 from "../../assets/img/abstract-3.jpg";
import img4 from "../../assets/img/abstract-4.jpg";

export default function Index() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isAnnual, setIsAnnual] = useState(true);
  // const [currency, setCurrency] = useState("USD"); //  currency
  // const [currencySymbol, setCurrencySymbol] = useState("$"); //  currency symbol
  const pricingRef = useRef(null);
  // const [prices, setPrices] = useState({
  //   basic: { monthly: 399, yearly: 3999 },
  //   professional: { monthly: 999, yearly: 9999 },
  //   premium: { monthly: 1799, yearly: 17999 },
  //   enterprise: { monthly: 2999, yearly: 29999 }
  // });
  
  // useEffect(() => {
  //   fetch("https://api.ipdata.co?api-key=d060a3127d7aa392230c21560791a6986f30fd24b1cdffbc24749657")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const countryCode = data.currency.code;
  //       const currencySymbol = data.currency.native;
  //       fetch(`https://v6.exchangerate-api.com/v6/79c7672fc6b8d853da006034/pair/USD/${countryCode}`)
        
  //         .then((response) => response.json())
  //         .then((data) => {
  //           const rates = data.conversion_rate;
  
  //           // console.log( rates,prices.basic.monthly,prices.basic.monthly * rates) 

  //           const localPrices = {
  //             basic: {
  //               monthly: Math.round(prices.basic.monthly * rates),
  //               yearly: Math.round(prices.basic.yearly * rates)
  //             },
  //             professional: {
  //               monthly: Math.round(prices.professional.monthly * rates),
  //               yearly: Math.round(prices.professional.yearly * rates)
  //             },
  //             premium: {
  //               monthly: Math.round(prices.premium.monthly * rates),
  //               yearly: Math.round(prices.premium.yearly * rates)
  //             },
  //             enterprise: {
  //               monthly: Math.round(prices.enterprise.monthly * rates),
  //               yearly: Math.round(prices.enterprise.yearly * rates)
  //             }
  //           };
  //           setCurrencySymbol(currencySymbol); 
            
  //           setPrices(localPrices); 
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching exchange rates:", error);
  //         });
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching user's location:", error);
  //     });
      
  // }, []);

  // Modal
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div id="pricingSection" ref={pricingRef}>
      <div class="lg:py-5   text-white bg-[#0f172a]">
        <main class="max-w-8xl mx-auto pt-10 pb-28 px-8">
          <div class="max-w-4xl mx-auto mb-14 text-center">
            <h1 class="text-4xl font-semibold mb-6 lg:text-5xl">
              Spectrum 360 <span className="text-purple-400">License Plans</span>
            </h1>
            <p class="text-lg text-gray-300 font-normal">
              Spectrum 360, the cornerstone of your organization's
              transformation journey, offers a range of license plans designed
              to cater to the diverse needs of businesses at different stages of
              their maturity assessment and improvement processes. These plans
              provide access to our integrated ecosystem, including Spectrum
              360, Spectra Hub, and Spectra Store, along with the advanced
              capabilities of our AI-driven Spectra Insight Engine. Here, we
              outline the tailored license plans available to organizations
              seeking to harness the full potential of Spectrum 360 for their
              digital and business transformation efforts.
            </p>
          </div>

          {/* Pricing toggle */}
          <div className="flex justify-center max-w-[14rem] m-auto mb-8 lg:mb-16">
            <div className="relative flex w-full p-1 bg-white  rounded-full">
              <span
                className="absolute inset-0 m-1 pointer-events-none"
                aria-hidden="true"
              >
                <span
                  className={`absolute inset-0 w-1/2 bg-indigo-500 rounded-full shadow-sm shadow-indigo-950/10 transform transition-transform duration-150 ease-in-out ${
                    isAnnual ? "translate-x-0" : "translate-x-full"
                  }`}
                ></span>
              </span>

              <button
                className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${
                  isAnnual ? "text-white  " : "text-slate-500"
                }`}
                onClick={() => setIsAnnual(true)}
                aria-pressed={isAnnual}
              >
                Monthly
              </button>
              <button
                className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${
                  isAnnual ? "text-slate-500" : "text-white "
                }`}
                onClick={() => setIsAnnual(false)}
                aria-pressed={isAnnual}
              >
                Yearly{" "}
                <span
                  className={`${
                    isAnnual ? "text-indigo-300" : "text-slate-300 "
                  }`}
                >
                  
                </span>
              </button>
            </div>
          </div>

          <div class="flex  flex-col justify-between  items-center  lg:flex-row lg:items-start">
            <div  class="w-full flex-1 mt-8 p-8 order-1 bg-sky-50 shadow-xl rounded-3xl sm:w-96 lg:w-full lg:order-1 lg:rounded-3xl">
              <div class="mb-2 pb-7 flex items-center border-b border-gray-300">
                <img src={img1} alt="" class="rounded-3xl w-20 h-20" />
                <div class="ml-5">
                  <span class="block text-lg lg:text-2xl text-black font-semibold">
                    Basic
                  </span>
                  <span>
                    <span class="font-medium text-gray-500 text-xl align-top">
                      $&thinsp;
                    </span>
                    <span class="text-lg lg:text-3xl text-black font-bold">
                      {isAnnual ? "399" : "3999"}{" "}
                    </span>
                  </span>
                  <span class="text-gray-500 font-medium">
                    {isAnnual ? "/ month" : "/ year"}
                  </span>
                </div>
              </div>
              <p className=" text-black font-semibold py-2">
              Tailored for small businesses or teams beginning their maturity assessment journey.
              </p>
              <ul class="mb-7 text-lg md:text-xl font-medium text-gray-500 ">
              <li class="flex  mb-4">
                  <img src={checkgrey_img} alt="arrow" />
                  <span class="ml-3">
                  Access to any 1 selected Community.{" "}
                  </span>
                </li>
                <li class="flex  mb-4">
                  <img src={checkgrey_img} alt="arrow-mark" />
                  <span class="ml-3">
                  Access to 1 subscribed community of any 1 maturity framework from Spectra Store.{" "}
                  </span>
                </li>
                <li class="flex  mb-4">
                  <img src={checkgrey_img} alt="arrow" />
                  <span class="ml-3">
                    {" "}
                    Basic access to Spectrum 360 assessment tools (Spectra Insights) </span>
                </li>
                <li class="flex  mb-4 lg:mb-[374px] ">
                  <img src={checkgrey_img} alt="arrow" />
                  <span class="ml-3">
                  Subscription to 1 public communities in Spectra Hub for knowledge sharing and engagement.  
                  </span>
                </li>
                
              
              </ul>
              <a href="/"
                onClick={openModal}
                className="flex justify-center cursor-pointer items-center bg-indigo-600 hover:bg-indigo-500 rounded-xl py-6 px-4 text-center text-white text-2xl"
              >
                Subscribe
                <img src={arrow_img} alt="arrow" className="ml-2" />
              </a>
            </div>

            <div  class="w-full flex-1 mt-8 p-8 order-1 bg-sky-50 shadow-xl rounded-3xl sm:w-96 lg:w-full lg:order-1 lg:rounded-3xl  ">
              <div className="mb-2 pb-8 flex items-center border-b border-gray-300">
                <img src={img2} alt="" className="rounded-3xl w-20 h-20" />
                <div className="ml-5">
                  <span className="block text-lg lg:text-3xl font-semibold text-black">
                    Professional
                  </span>
                  <span>
                    <span className="font-medium text-gray-500 text-xl align-top">
                      $&thinsp;
                    </span>
                    <span className="text-lg lg:text-3xl font-bold text-black">
                      {isAnnual ? "999" : "9999"}{" "}
                    </span>
                  </span>
                  <span className="font-medium text-gray-500">
                    {isAnnual ? "/ month" : "/ year"}
                  </span>
                </div>
              </div>
              <p className=" text-black font-semibold py-2">
              Designed for organizations requiring deeper insights and more extensive tools.
              </p>
              <ul className="mb-10 font-medium text-lg md:text-xl text-gray-500 ">
              <li className="flex mb-4">
                  <img src={checkgrey_img} alt="arrow" />
                  <span className="ml-3">
                    {" "}
                    Access to any 5 selected Community.
                  </span>
                </li>
                <li className="flex mb-4">
                  <img src={checkgrey_img} alt="arrow" />
                  <span className="ml-3">
                    {" "}
                     Access to 5 subscribed community of any 2-maturity framework for each subscribed community from Spectra Store. 
                  </span>
                </li>
                <li class="flex mb-4">
                  <img src={checkgrey_img} alt="arrow" />
                  <span class="ml-3">
                  Full access to Spectrum 360 assessment tools and reporting features (Spectra Insights). 
                  </span>
                </li>
                <li class="flex mb-4">
                  <img src={checkgrey_img}alt="arrow" />
                  <span class="ml-3">
                  Can create one private community on Spectra Hub for their practice team or CoE (Center of Excellence) of subscribed community and contribute to the knowledge feed.  
                  </span>
                </li>
                <li class="flex mb-4 lg:mb-12">
                  <img src={checkgrey_img} alt="arrow" />
                  <span class="ml-3">
                  Ability to subscribe to unlimited public communities in Spectra Hub of subscribed community.  

 
                  </span>
                </li>

                
              </ul>
              <a href="/"
                onClick={openModal}
                className="flex justify-center cursor-pointer items-center bg-indigo-600 hover:bg-indigo-500 rounded-xl py-6 px-4 text-center text-white text-2xl"
              >
                Subscribe
                <img src={arrow_img} alt="arrow" className="ml-2" />
              </a>
            </div>

            <div class="w-full flex-1 mt-8 p-8 order-1 bg-gray-800 shadow-xl rounded-3xl sm:w-96 lg:w-full lg:order-1 lg:rounded-3xl  ">
              <div class="mb-2 pb-8 flex items-center border-b border-gray-400 ">
                <img src={img4} alt="" className="rounded-3xl w-20 h-20" />
                <div className="ml-5">
                  <span className="block text-lg lg:text-3xl font-semibold text-white">
                    Premium
                  </span>
                  <span>
                    <span className="font-medium text-xl align-top text-gray-300">$&thinsp;</span>
                    <span className="text-lg lg:text-3xl font-bold text-slate-100">
                      {isAnnual ? "1799" : "24999"}{" "}
                    </span>
                  </span>
                  <span className="font-medium text-gray-300">
                    {isAnnual ? "/ month" : "/ year"}
                  </span>
                </div>
              </div>
              <p className=" text-gray-200 font-semibold py-2">
              For large or complex organizations seeking comprehensive features and strategic support.
              </p>
              <ul className="mb-11 font-medium text-lg md:text-xl text-white">
              <li className="flex mb-4">
                  <img src={checkgrey_img} alt="arrow" />
                  <span className="ml-3">
                    {" "}
                    Access to any 10 selected Community (practice area). 
                  </span>
                </li>
                <li className="flex mb-4">
                  <img src={checkgrey_img} alt="arrow" />
                  <span className="ml-3">
                    {" "}
                    Access to 10 subscribed community (practice area) of unlimited maturity framework for each subscribed community from Spectra Store.
                  </span>
                </li>
                <li className="flex mb-4">
                  <img src={checkgrey_img} alt="arrow"/>
                  <span className="ml-3">
                  Advanced assessment tools, benchmarking, and analytics in Spectrum 360.  
                  </span>
                </li>
                <li className="flex mb-4">
                  <img src={checkgrey_img} alt="arrow" />
                  <span class="ml-3">
                  Includes some features of Spectra Insight for enhanced analysis and recommendations.  
                  </span>
                </li>
                <li className="flex mb-4 lg:mb-20">
                  <img src={checkgrey_img} alt="arrow" />
                  <span class="ml-3">
                  Capabilities to create any 3 private communities from subscribed communities (practice area) in Spectra Hub for internal collaboration.  
                  </span>
                </li>

               
              </ul>
              <a href="/"
                onClick={openModal}
                className="flex justify-center cursor-pointer items-center bg-indigo-600 hover:bg-indigo-500 rounded-xl py-6 px-4 text-center text-white text-2xl"
              >
                Subscribe
                <img src={arrow_img} alt="arrow" className="ml-2" />
              </a>
            </div>

            <div 
            className="w-full flex-1 mt-8 p-8 order-4 bg-sky-50 shadow-xl text-gray-400 rounded-3xl sm:w-96 lg:w-full lg:order-4 lg:rounded-3xl ">
              <div className="mb-2 pb-7 flex items-center border-b border-gray-400">
                <img src={img3} alt="" className="rounded-3xl w-20 h-20" />
                <div className="ml-5">
                  <span className="block text-lg lg:text-2xl text-black font-semibold">
                    Enterprise
                  </span>
                  <span>
                    <span className="font-medium text-xl align-top">$&thinsp;</span>
                    <span className="text-lg lg:text-3xl font-bold text-black">
                      {isAnnual ? "3999" : "39999"}{" "}
                    </span>
                  </span>
                  <span className="font-medium">
                    {isAnnual ? "/ month" : "/ year"}
                  </span>
                </div>
              </div>
              <p className="text-black font-semibold py-2">
                For Organizations with Unique Needs, requiring a tailored
                solution.
              </p>
             
              <ul className="mb-2 font-medium text-gray-500 text-lg md:text-xl ">
                <li className="flex mb-4">
                  <img src={checkgrey_img} alt="arrow" />
                  <span class="ml-3">
                  Unlimited access to all communities (practice area) & maturity frameworks in the Spectra Store. 
                  </span>
                </li>
                <li className="flex mb-4">
                  <img src={checkgrey_img} alt="arrow" />
                  <span className="ml-3">
              
                  Full access to Spectra Insight for creating custom maturity frameworks and advanced forecasting.  
                  </span>
                </li>
                <li className="flex mb-4">
                  <img src={checkgrey_img} alt="arrow" />
                  <span className="ml-3">
              
                  Comprehensive access to all Spectrum 360 tools, including dedicated support and customization options.  
                  </span>
                </li>
                <li className="flex mb-4">
                  <img src={checkgrey_img} alt="arrow" />
                  <span className="ml-3">
              
                  Ability to host multiple private communities in Spectra Hub for various practice areas or COEs.  
                  </span>
                </li>
                <li className="flex mb-4">
                  <img src={checkgrey_img} alt="arrow" />
                  <span className="ml-3">
              
                  Dedicated server hosting for enhanced security and performance.   
                  </span>
                </li>
                <li className="flex mb-2">
                  <img src={checkgrey_img} alt="arrow" />
                  <span className="ml-3">
              
                  Consulting – 5 consulting sessions – SME hand holding (AeroAegis value-added service). 


                  </span>
                </li>
             
              </ul>
              
              <a href="/"
                onClick={openModal}
                className="flex justify-center cursor-pointer items-center bg-indigo-600 hover:bg-indigo-500 rounded-xl py-6 px-4 text-center text-white text-2xl"
              >
                Subscribe
                <img src={arrow_img} alt="arrow" className="ml-2" />
              </a>
            </div>

          </div>
        </main>
      </div>
      {/* Modal */}
      <Modal isOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
}
