import React from 'react'

export default function footer() {
  return (
    <div>
       <footer className="w-full pt-10 bg-[#111827]" aria-labelledby="footer-heading">
                <h2 id="footer-heading" className="sr-only">Footer</h2>
                <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 lg:px-16">
                  <div className="flex flex-col items-baseline space-y-6">
                  
                    <div className="mx-auto">
                      <span className="mx-auto mt-2 text-sm text-gray-500">
                        Copyright © {(new Date().getFullYear())}
                        <a href="https://aeroaegis.com/" className="mx-2 text-blue-500 hover:text-gray-500" target="_blank" rel="noopener noreferrer">AeroAegis</a>
                      </span>
                    </div>
                  </div>
                </div>
              </footer>
    </div>
  )
}
