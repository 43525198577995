import React from "react";
import img1 from "../../assets/img/Homepage/5056.jpg";
export default function Paralaxx1() {
  return (
    <div>
      <section className="px-4 py-20  relative ">
        <div
          className="absolute w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat bg-fixed"
          style={{
            backgroundImage: `url(${img1})`,
          }}
        ></div>
        <div className="absolute w-full h-full top-0 left-0 bg-black opacity-60"></div>
        <div className="max-w-2xl mb-10 md:mx-auto sm:text-center lg:max-w-[64rem] md:mb-5 relative z-10">
          <p className="relative tracking-wide text-center font-semibold lg:text-2xl text-white md:text-lg">Spectrum 360 revolutionizes the way organizations measure and navigate their digital and business transformation journeys. Our platform harnesses the power of advanced AI to deliver a comprehensive, engaging, and insightful experience, ensuring that your organization not only meets but exceeds its potential in every practice area. Discover the future of organizational assessment and improvement with Spectrum 360.
          <br />
            Its integration with Spectra Hub and Spectra Store propels Spectrum
            360 beyond a mere assessment tool, transforming it into a dynamic
            platform that connects, educates, and empowers organizations on
            their journey to excellence.
          </p>
        </div>
      </section>
    </div>
  );
}
