import React from "react";
import img1 from "../../assets/img/Homepage/70176.jpg";
import img_icon1 from "../../assets/img/Homepage/ai-svgrepo-com.svg";
import img_icon2 from "../../assets/img/Homepage/graph-new-up-svgrepo-com.svg";
import img_icon3 from "../../assets/img/Homepage/guide-svgrepo-com.svg";

export default function Section4() {
  return (
    <section class="bg-[#0f172a]">
    <div class="container px-6 lg:px-20 py-10 mx-auto">
        <div class="lg:flex lg:items-center place-items-center">
            <div class="w-full space-y-12 lg:w-1/2 ">
                <div>
                    <h1 class="text-2xl font-semibold  capitalize lg:text-4xl text-white"><span className=" text-purple-400">Spectra Insight</span> <br/> AI-Powered Engine for Transformation Excellence</h1>

                    <div class="mt-2">
                        <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
                        <span class="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"></span>
                        <span class="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"></span>
                    </div>
                   
<p className=" text-white lg:text-lg pt-2">Spectra Insight seamlessly integrates with the Spectrum 360 platform, enhancing its core capabilities with powerful AI analysis and recommendations. This integration enables a fluid workflow from assessment through to strategic planning and implementation.</p>
                </div>
                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2  rounded-xl md:mx-4 text-white bg-blue-500">
                    <img src={img_icon1} className="w-8 lg:w-10"></img>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-xl font-semibold  capitalize text-white">AI-Driven Recommendations</h1>

                        <p class="mt-3 lg:text-lg text-gray-300 dark:text-gray-300">
                        Spectra Insight analyzes your organization's data, comparing it against industry benchmarks and best practices to offer customized recommendations.
                        </p>
                    </div>
                </div>

                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2  rounded-xl md:mx-4 text-white bg-blue-500">
                    <img src={img_icon2} className="w-8 lg:w-12"></img>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-xl font-semibold  capitalize text-white">Maturity Curve Forecasting</h1>

                        <p class="mt-3 text-gray-300 lg:text-lg dark:text-gray-300">
                        With Spectra Insight, you can forecast your organization's maturity curve, understanding how different strategies and initiatives will likely impact your maturity over time.
                        </p>
                    </div>
                </div>
                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2  rounded-xl md:mx-4 text-white bg-blue-500">
                    <img src={img_icon3} className="w-8 lg:w-10"></img>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-xl font-semibold capitalize text-white">Guided Framework Creation</h1>

                        <p class="mt-3 text-gray-300 lg:text-lg dark:text-gray-300">
                        One of Spectra Insight's most innovative features is its ability to guide your practice team in creating or enhancing maturity frameworks.
                        </p>
                    </div>
                </div>

              
            </div>

            <div class="hidden lg:flex lg:items-center lg:w-1/2 lg:justify-center">
                <img class="w-[28rem] h-[28rem] object-cover xl:w-[34rem] xl:h-[34rem] rounded-full" src={img1} alt=""/>
            </div>
        </div>

        <hr class="my-12 border-gray-700"/>

     
    </div>
</section>
  );
}
